.border-radius {
  border-top-left-radius: 1.125rem !important;
}

.filter-show {
  opacity: 1;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

.filter-hide {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}
