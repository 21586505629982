.custom-table .ant-table-thead > tr > th {
  background-color: white;
  color: var(--nav-color);
  font-weight: var(--font-500);
  padding: 0.625rem;
}
.custom-table .ant-table-tbody > tr > td {
  padding: 0.625rem;
}
.custom-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 1.563rem;
}
.custom-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 1.563rem;
}

.custom-table .ant-table {
  border-radius: 1.563rem;
}

.tab-container .ant-tabs {
  width: 100%;
}

.transparent-search .ant-input {
  background-color: transparent;
}
.transparent-search .ant-input-affix-wrapper {
  background-color: transparent;
}

.transparent-search .ant-input-search-button {
  background-color: transparent;
}

.tabs .ant-tabs-nav::before {
  border-bottom: none;
}
