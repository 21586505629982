/* box-shadow classes */
.alert-shadow {
  border-radius: var(--Card-Roundness, 18px) !important;
  border-bottom: 1px solid var(--anti-flash-white) !important;
  background: #fff !important;
  box-shadow: 3px 0px 0px 0px var(--warning-color) inset !important;
}
.ok-shadow {
  border-radius: var(--Card-Roundness, 18px) !important;
  border-bottom: 1px solid var(--anti-flash-white) !important;
  background: #fff !important;
  box-shadow: 3px 0px 0px 0px var(--ok-color) inset !important;
}
.informative-shadow {
  border-radius: var(--Card-Roundness, 18px) !important;
  border-bottom: 1px solid var(--anti-flash-white) !important;
  background: #fff !important;
  box-shadow: 3px 0px 0px 0px var(--informative-color) inset !important;
}

.sea-pea-green-shadow {
  border-radius: var(--Card-Roundness, 18px) !important;
  border: 2px solid var(--snow-pea-green, #6bd17c) !important;
  background: var(--white, #fff) !important;

  /* card shadow */
  box-shadow: 0px 18px 10px 0px rgba(0, 0, 0, 0.02) !important;
}

.profile-shadow {
  border-radius: var(--Card-Roundness, 18px) !important;
  border: 1px solid var(--Surface-Bg, #eff3f4) !important;
  background: var(--white, #fff) !important;

  /* card shadow */
  box-shadow: 0px 18px 10px 0px rgba(0, 0, 0, 0.02) !important;
}

.dashboard-card {
  border-radius: var(--Card-Roundness, 18px) !important;
  background: var(--white, #fff) !important;

  /* card shadow */
  box-shadow: 0px 18px 10px 0px rgba(0, 0, 0, 0.02) !important;
}

.alert-box-wbb {
  border-radius: 9px !important;
  border: 1px solid var(--Anti-Flash-White, #eef0f4) !important;
  background: var(--white, #fff) !important;
  box-shadow: -3px 0px 0px 0px #f55468 !important;
}
.ok-shadow-wbb {
  border-radius: var(--Card-Roundness, 8px) !important;
  background: #fff !important;
  box-shadow: 3px 0px 0px 0px var(--ok-color) inset !important;
}
.informative-shadow-wbb {
  border-radius: var(--Card-Roundness, 8px) !important;
  background: #fff !important;
  box-shadow: 3px 0px 0px 0px var(--informative-color) inset !important;
}

.notification-critical {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: #fcd4d9 !important;
  color: var(--warning-color) !important;
  font-weight: var(--font-500) !important;
  line-height: 126% !important;
  width: 5.188rem;
  text-align: center !important;
}

.notification-normal {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: #d8f3dc !important;
  color: var(--normal-color) !important;
  font-weight: var(--font-500) !important;
  line-height: 126% !important;
  width: 5.188rem;
  text-align: center !important;
}

.notification-informative {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: #e1e2ef !important;
  font-weight: var(--font-500) !important;
  line-height: 126% !important;
  width: 5.188rem;
  text-align: center !important;
}

.planned-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--nav-color) !important;
  padding: 0rem 0.4rem !important;
  color: var(--white) !important;
  line-height: 126% !important;
  width: 5.188rem;
  text-align: center !important;
}

.unplanned-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--white, #fff) !important;
  padding: 0rem 0.4rem !important;
  color: var(--nav-color) !important;
  line-height: 126% !important;
  width: 5.188rem;
  text-align: center !important;
}

.pending-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--availability-color) !important;
  color: var(--pending-color) !important;
  padding: 0.563rem 1rem !important;
  line-height: 126%;
  width: 4rem;
  text-align: center !important;
  font-size: 0.688rem !important;
}

.closed-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--availability-color) !important;
  color: var(--closed-color) !important;
  padding: 0.563rem 1rem !important;
  line-height: 126%;
  width: 4rem;
  text-align: center !important;
  font-size: 0.688rem !important;
}

.inProgress-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--performance-color) !important;
  color: var(--inProgress-color) !important;
  padding: 0.563rem 1rem !important;
  line-height: 126%;
  width: 4rem;
  text-align: center !important;
  font-size: 0.688rem !important;
}

.start-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--primary-color) !important;
  color: var(--white) !important;
  padding: 0.563rem 1rem !important;
  line-height: 126%;
  width: 4rem;
  text-align: center !important;
  font-size: 0.688rem !important;
}

.simple-button {
  border-radius: 0.375rem !important;
  border: 1px solid var(--columbia-gray-color) !important;
  background: var(--white) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07) !important;
}

.primary-button {
  border-radius: 0.375rem !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.07),
    0px 4px 4px 0px rgba(0, 0, 0, 0.07) !important;
}

.invite-pending-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--invite-pending-bg) !important;
  color: var(--invite-pending) !important;
  padding: 0.438rem 0.625rem !important;
  width: 6rem;
  text-align: center !important;
}

.active-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--active-bg) !important;
  color: var(--normal-color) !important;
  padding: 0.438rem 0.625rem !important;
  width: 6rem;
  text-align: center !important;
}

.deleted-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--deleted-bg) !important;
  color: var(--deleted-color) !important;
  padding: 0.438rem 0.625rem !important;
  width: 6rem;
  text-align: center !important;
}

.not-configured-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--invite-pending-bg) !important;
  color: var(--invite-pending) !important;
  padding: 0.2rem 0.2rem !important;
  width: 7rem;
  text-align: center !important;
}

.active-configure-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--active-bg) !important;
  color: var(--normal-color) !important;
  padding: 0.2rem 0.2rem !important;
  width: 7rem;
  text-align: center !important;
}

.error-configure-tag {
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.09) !important;
  background: var(--deleted-bg) !important;
  color: var(--deleted-color) !important;
  padding: 0.2rem 0.2rem !important;
  width: 7rem;
  text-align: center !important;
}

.in-active-configure-tag {
  border-radius: 6px !important;
  border: 1px solid var(--columbia-gray-color) !important;
  color: var(--nav-color) !important;
  padding: 0.2rem 0.2rem !important;
  width: 7rem;
  text-align: center !important;
}
