:root {
  --primary-color: #21223d;
  --hover-color: #ffffff21;
  --divider-color: #ffffff1c;
  --snow-pea-green: #6bd17c;
  --availability-color: #ffd2c3;
  --lagoon-color: #4a9d90;
  --pending-color: #d65226;
  --navy-seal-color: #273d92;
  --closed-color: #d60505;
  --inProgress-color: #22894b;
  --performance-color: #a7f2c5;
  --quality-color: #d2d4ff;
  --disabled-color: #eff3f4;
  --input-border-color: #cbd5e1;
  --columbia-gray-color: #cbd5e1;
  --nav-color: #63658e;
  --warning-color: #f55468;
  --normal-color: #0ab074;
  --ok-color: #6bd17c;
  --informative-color: #21223d;
  --anti-flash-white: #eef0f4;
  --invite-pending-bg: #ffddd1;
  --invite-pending: #ff710b;
  --deleted-color: #fa384f;
  --active-bg: #d2fce3;
  --deleted-bg: #fbd5d5;
  --white: #fff;
  --black: #000;
}
