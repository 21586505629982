.border-bottom {
  border-bottom: 1px solid var(--columbia-gray-color);
}

.w-7 {
  width: 7rem !important;
}

.max-input .ant-input-number-handler-down {
  border: none;
}
.max-input .ant-input-number-handler-up {
  border: none;
}
.max-input .ant-input-number-handler-wrap {
  flex-direction: row-reverse;
  align-items: center;
  width: 32px;
}
