.custom-downtime-table .ant-table-thead > tr > th {
  background-color: white;
  font-weight: var(--font-500);
  padding: 0.625rem;
}
.custom-downtime-table .ant-table-tbody > tr > td {
  padding: 0.625rem;
}
.custom-downtime-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 1rem !important;
}
.custom-downtime-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  border-start-end-radius: 1rem;
}

.custom-downtime-table {
  width: 100%;
}
.custom-downtime-table .ant-table {
  border-radius: 1.563rem;
}

.custom-downtime-table .ant-table-column-sorter {
  // display: none;
}
