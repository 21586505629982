.tabs .ant-tabs-nav::before {
  border-bottom: none;
}

.custom-tabs-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  border-left: 1px solid var(--columbia-gray-color);
  border-top-left-radius: 18px;
  padding-left: 0.5rem;
}

.custom-tabs {
  list-style: none;
  padding: 0;
  margin-top: 2.175rem;
}

.custom-tab {
  cursor: pointer;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 4px;
  position: relative; /* Added to establish a positioning context */
  transition: background-color 0.3s;
}

.selected {
  color: var(--primary-color);
}

.unselected {
  color: gray;
}
.selected::before {
  content: "•";
  position: absolute;
  left: -3%;
  top: 45%;
  transform: translateY(-50%);
  display: inline-block;
  margin-right: 8px;
  color: black; /* Color of the dot, adjust as needed */
  font-size: 2rem;
}
