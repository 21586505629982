.SideMenu {
  height: 100%;
}
.SideMenuVertical {
  height: 100%;
  padding: 1rem 0;
  border-top-right-radius: 18px;
  background: var(--primary-color);
  overflow: scroll;
}
.SideMenuVertical::-webkit-scrollbar {
  display: none;
}
.ant-menu-item-divider {
  border: none !important;
  width: 11rem;
  height: var(--Reset, 1px);
  margin: auto;
  background-color: var(--divider-color);
}
.ant-menu-submenu-title {
  font-weight: 600;
}
.ant-menu-submenu-title:active {
  background-color: var(--hover-color) !important;
}
.ant-menu-sub {
  background: var(--primary-color) !important;
}
.ant-menu-item-selected {
  background-color: var(--hover-color) !important;
}
.ant-menu-item-selected:active {
  background-color: var(--hover-color) !important;
}
.ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: var(--hover-color) !important;
}
.ant-menu-item-only-child {
  width: 12rem !important;
  margin-left: 3rem !important;
  text-align: start !important;
  padding-left: 0.5rem !important;
  height: 2rem !important;
}

.ant-menu-title-content {
  margin-right: 1rem;
}
