.margin-0 {
  margin: 0;
}

.item-selected {
  background: var(--disabled-color) !important;
  padding: 0.75rem !important;
  border-radius: 0.25rem !important;
}

.item-unselected {
  padding: 0.75rem !important;
  border-radius: 0.25rem !important;
}
