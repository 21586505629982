.input-border-bottom {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
}

.multi-select {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
  color: var(--black) !important;
  width: 100%;
}

.multi-select .ant-select-selector {
  padding: 0 !important;
}

.multi-select .ant-select-selection-search {
  inset-inline-start: 0 !important;
}

.ant-select-min-width {
  min-width: 8rem;
}

.ant-select-min-width .ant-select-selection-search {
  inset-inline-start: 0 !important;
}

.select {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
}

.selected-display-border {
  border: 2px solid var(--snow-pea-green) !important;
}

.tick-mark-position {
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: -8px;
  right: -5px;
}

.custom-table {
  width: 100%;
}

.custom-table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 1.563rem;
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 1.563rem;
}

.custom-table .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 1.563rem;
}

.custom-table .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 1.563rem;
}
