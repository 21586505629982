.margin-0 {
  margin: 0;
}

.custom-tab-title {
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
    }
  }
}
