/* font weight classes */
:root {
  --font-200: 200;
  --font-300: 300;
  --font-400: 400;
  --font-500: 500;
  --font-600: 600;
  --font-700: 700;
  --font-800: 800;
  --font-bold: bold;
  --font-bolder: bolder;
}

.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-bold {
  font-weight: bold;
}
.font-weight-bolder {
  font-weight: bolder;
}
