.border-none {
  border: none;
}

.svg-height-width {
  height: auto;
  width: 2rem;
}

.padding-1 {
  padding: 1rem 0 !important;
}
