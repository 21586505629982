.custom-table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 1.563rem;
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 1.563rem;
}

.custom-table .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 1.563rem;
}

.custom-table .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 1.563rem;
}

.transparent-search .ant-input {
  background-color: transparent;
}
.transparent-search .ant-input-affix-wrapper {
  background-color: transparent;
}

.transparent-search .ant-input-search-button {
  background-color: transparent;
}

.user-input {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
  color: var(--black) !important;
}

.user-select {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
  color: var(--black) !important;
}

.user-select .ant-select-selector {
  padding: 0 !important;
  font-size: 1rem;
}

.user-select .ant-select-selection-search {
  inset-inline-start: 0 !important;
}

.ant-modal .ant-modal-title {
  font-size: 1.875rem;
}
