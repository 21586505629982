.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}
.background-transparent {
  background-color: transparent;
}

.customise-tab {
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    width: 100%;
    gap: 0.5rem;
    .ant-tabs-tab {
      width: 25%;
      border-top: none;
      border-right: none;
      border-left: none;
      background: transparent;
    }
  }
}
