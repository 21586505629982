.rejection-input .ant-input-number-handler-down {
  border: none;
}
.rejection-input .ant-input-number-handler-up {
  border: none;
}
.rejection-input .ant-input-number-handler-wrap {
  flex-direction: row-reverse;
  align-items: center;
  width: 32px;
}
.rejection-select {
  width: 100%;
}
