.custom-select {
  width: 100%;
}

.custom-select .ant-select-selector {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 1px solid var(--columbia-gray-color) !important;
}

.custom-modal {
  min-width: 38rem;
}

.custom-modal .ant-modal-title {
  font-size: 1.875rem;
}

.radio-group {
  display: flex;
  gap: 0.5rem;
  border-radius: 10px;
  flex-wrap: wrap;
}

.radio-button {
  min-width: 8rem;
}
