.dashboard-side-list {
  .ant-divider-horizontal {
    margin: 0;
  }
  .ant-list-item {
    padding: 0.5rem 0;
  }
}

.ant-card {
  border-radius: 18px;
}

.selected-card::before {
  content: "";
  position: absolute;
  border-width: 9px;
  border-style: solid;
  border-color: var(--nav-color) transparent transparent transparent;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.report-radio-group {
  display: flex;
  gap: 0.5rem;
  border-radius: 10px;
}

.report-radio-button {
  min-width: 10rem;
}

.report-select {
  border-bottom: 1px solid var(--columbia-gray-color);
}

.report-select .ant-select-selector {
  padding: 0;
}
