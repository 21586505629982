.margin-0 {
  margin: 0;
}

.custom-calendar {
  background-color: var(--disabled-color);
  .ant-picker-panel {
    background-color: var(--disabled-color);
  }
}

.border-bottom {
  border-bottom: 2px solid var(--anti-flash-white) !important;
}
