.container {
  width: 100vw;
  height: 100vh;
  mix-blend-mode: luminosity;
  position: "relative";
}

.ant-space-item {
  width: 100%;
}
.gap-100 {
  gap: 100px;
}

.width-366 {
  width: 366px;
}

.height-54 {
  height: 54px;
}

.ant-card-body {
  padding: 40px 50px !important;
}
