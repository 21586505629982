.custom-radio-group {
  display: flex;
  gap: 0.5rem;
}

.radio-group-unselected {
  padding: 0.5rem !important;
  border: 1px solid var(--columbia-gray-color) !important;
  border-radius: 0.25rem !important;
}

.radio-group-selected {
  padding: 0.5rem !important;
  background-color: var(--primary-color) !important;
  border-radius: 0.25rem !important;
  div {
    background-color: var(--primary-color);
  }
  p {
    color: var(--white);
  }
}

.selected {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--white) !important;
      background-color: var(--primary-color) !important;
    }
  }
}

.user-input {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
  color: var(--black) !important;
}

.user-select {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
  color: var(--black) !important;
}

.user-select .ant-select-selector {
  padding: 0 !important;
  font-size: 1rem;
}

.user-select .ant-select-selection-search {
  inset-inline-start: 0 !important;
}

.ant-modal .ant-modal-title {
  font-size: 1.875rem;
}
