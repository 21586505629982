.AppHeader {
  padding: 8px 12px;
}

.HeaderProfileWrapper {
  gap: 0.5rem;
}

.HeaderCardPadding {
  padding: 9px 15px;
}

.color-black {
  color: var(--black);
}
