.margin-0 {
  margin: 0;
}

.custom-radio-group {
  display: flex;
  gap: 0.5rem;
}

.radio-group-unselected {
  padding: 0.5rem !important;
  border: 1px solid var(--columbia-gray-color) !important;
  border-radius: 0.25rem !important;
}

.radio-group-selected {
  padding: 0.5rem !important;
  background-color: var(--primary-color) !important;
  border-radius: 0.25rem !important;
  div {
    background-color: var(--primary-color);
  }
  p {
    color: var(--white);
  }
}

.selected {
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--white) !important;
      background-color: var(--primary-color) !important;
    }
  }
}

.custom-collapse {
  background-color: transparent;
}

.align-items-center {
  align-items: center;
}

.position-absolute {
  position: absolute;
  top: 25%;
  left: -20px;
  font-size: 1rem;
}
