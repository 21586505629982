.custom-table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 1.563rem;
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 1.563rem;
}

.custom-table .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 1.563rem;
}

.custom-table .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 1.563rem;
}

.transparent-search .ant-input {
  background-color: transparent;
}
.transparent-search .ant-input-affix-wrapper {
  background-color: transparent;
}

.transparent-search .ant-input-search-button {
  background-color: transparent;
}

.select-factory {
  min-width: 12rem;
  .ant-select-selector {
    background-color: transparent !important;
    border-radius: 4px;
  }
}
