.custom-border-bottom-left-radius {
  border-bottom-left-radius: 1.125rem;
}
.custom-border-bottom-right-radius {
  border-bottom-right-radius: 1.125rem;
}
.custom-border-top-right-radius {
  border-top-right-radius: 1.125rem;
}
.custom-border-top-left-radius {
  border-top-left-radius: 1.125rem;
}
