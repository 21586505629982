/* font color classes */
.font-color-nav {
  color: var(--nav-color);
}

.font-color-primary {
  color: var(--primary-color);
}

.font-color-white {
  color: var(--anti-flash-white);
}

.font-color-columbia-gray {
  color: var(--columbia-gray-color);
}
