@import "./CSS/border.css";
@import "./CSS/fontColor.css";
@import "./CSS/fontWeight.css";
@import "./CSS/variables.css";
@import "./CSS/backgroundColor.css";
@import "./CSS/boxShadow.css";
@import "./CSS/display.css";
@import "./CSS/dimension.css";
@import "antd-css-utilities/utility.min.css";

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.AppFooter {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}
.SideMenuAndPageContent {
  display: flex;
  height: calc(100vh - 69px);
  /* height: 100%; */
  /* flex: 1; */
  background-color: var(--disabled-color);
}
.PageContent {
  padding-left: 12px;
}
.ant-card-bordered {
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}

.border-radius-8 {
  border-radius: 8px;
}
.border-radius-6 {
  border-radius: 6px;
}

.slick-dots li.slick-active button {
  height: 5px !important;
  border-radius: 5px;
  opacity: 0.71;
  background: var(--nav-color) !important;
}

.slick-dots li:not(.slick-active) button {
  border-radius: 5px;
  /* width: 5px !important;
  height: 5px !important; */
  opacity: 0.4;
  background: var(--columbia-gray-color) !important;
}
.ant-pagination {
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
}

.ant-pagination .ant-pagination-item-active {
  border-color: var(--nav-color) !important;
}

.ant-pagination .ant-pagination-item {
  border-color: #dfe3e8;
}

.ant-pagination .ant-pagination-item-active:hover {
  border-color: var(--nav-color);
}

.ant-pagination .ant-pagination-item-active a {
  color: var(--nav-color);
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: var(--nav-color);
}

.ant-tabs-tab-active {
  background: var(--primary-color) !important;
  padding: 0.2rem 1rem !important;
  border-radius: 6px !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white) !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background-color: transparent;
}

.ant-tabs-tab {
  border-bottom: 1.5px solid var(--columbia-gray-color) !important;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: var(--nav-color);
  padding: 0 0.8rem !important;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 16px;
}

.ant-select-selection-item-remove {
  svg {
    height: 100%;
  }
}

.positioning {
  top: 1rem;
  right: 1rem;
  z-index: 2;
}
