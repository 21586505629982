/* background classes */
.bg-warning {
  background-color: var(--warning-color);
}
.bg-ok {
  background-color: var(--ok-color);
}
.bg-informative {
  background-color: var(--informative-color);
}
