/* border classes */
.border-columbia-gray {
  border: 1px solid var(--columbia-gray-color);
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-6 {
  border-radius: 6px;
}
