.ant-modal-min-width {
  min-width: 60rem;
}

.ant-select-min-width {
  min-width: 10rem;
}

.ant-select-min-width .ant-select-selector {
  padding: 0 !important;
}

.select {
  border-bottom: 1px solid var(--columbia-gray-color) !important;
  color: var(--black) !important;
}

.select .ant-select-selection-search {
  inset-inline-start: 0 !important;
}

.date-picker-border-bottom {
  border-bottom: 1px solid var(--columbia-gray-color);
}
